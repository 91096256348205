import TagManager from 'react-gtm-module';
import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';
import { eventDispatch } from '@medifind/utils';
import { localStorageMF, migrateReduxLocalStorageValue } from '../utils';

const dev = ['LOCAL'].includes(process.env.NX_ENV);
const dataLayerName = `${process.env.NX_APP}_Data_Layer`.replace(/-/g, '_');
let hostedWidgetName; // we can't store hostedWidgetName in globalState because it valid for current session only

const TRACKING_ACTIVE = !dev && process.env.NX_GOOGLE_TAG_MANAGER;
let trackingLoaded = false;
const initialState = {
  siteViews: 1,
  daysVisited: 1,
  firstTimeToday: true,
  shareClickCount: 0,
  consentLoaded: false,
  cookieAccepted: false,
  lastVisited: new Date().getTime(),
  singleEventActions: [],
  account: null,
  globalParams: {},
};

const queue = {};
const isInitialized = {};
const initializedGtmIds = [];

TagManager.dataLayer = new Proxy(TagManager.dataLayer, {
  apply: (target, thisArg, argumentsList) => {
    if (!isInitialized[dataLayerName]) {
      queue[dataLayerName] ||= [];
      queue[dataLayerName].push(argumentsList[0]);
    } else {
      return Reflect.apply(target, thisArg, argumentsList);
    }
  },
});

const storeName = 'googleAnalytics';
export const useGoogleAnalytics = create(
  devtools(
    persist(
      (set) => ({
        ...initialState,
        set,
        reset: () => set({ ...initialState }, false, { type: 'reset' }),
        getInitialState: () => ({ ...initialState }),
      }),
      {
        name: `${process.env.NX_APP}-${storeName}`,
        version: 0,
        storage: createJSONStorage(() => localStorageMF),
        onRehydrateStorage: () => (state) => {
          // We don't want to save consent loaded state because it's going to change every page reload
          state.consentLoaded = false;

          migrateReduxLocalStorageValue({ state, key: 'google' });
        },
      },
    ),
    {
      name: `${process.env.NX_APP}`,
      store: storeName,
    },
  ),
);
let postMessageListener = null;
export const initTagManager = (params = {}, globalParams = null) => {
  if (process.env.NX_GOOGLE_TAG_MANAGER) {
    const tagManagerArgs = getGtmInitializationArgs(params);

    if (initializedGtmIds.includes(tagManagerArgs.gtmId)) {
      return;
    }
    initializedGtmIds.push(tagManagerArgs.gtmId);

    if (params.hostedWidgetName) {
      hostedWidgetName = params.hostedWidgetName;
    }

    const pageLoaded = () => {
      if (globalParams) {
        useGoogleAnalytics.setState((state) => ({ globalParams: { ...state.globalParams, ...globalParams } }), false, {
          type: 'setGA4GlobalParam',
        });
      }
      setTimeout(() => {
        if (TRACKING_ACTIVE) {
          const googleState = useGoogleAnalytics.getState();

          tagManagerArgs.dataLayer = {
            ...params,
            ...googleState,
            ...googleState.globalParams,
            ...getSessionScopedGoogleState(),
          };
          TagManager.initialize(tagManagerArgs);
          isInitialized[dataLayerName] = true;
          queue[dataLayerName]?.forEach((val) => TagManager.dataLayer(val));
          setTimeout(() => {
            const googleState = useGoogleAnalytics.getState();
            TagManager.dataLayer({
              dataLayerName,
              dataLayer: {
                event: 'afterLoad',
                ...googleState,
                ...googleState.globalParams,
                ...getSessionScopedGoogleState(),
              },
            });
            trackingLoaded = true;
            eventDispatch('afterLoad', {});
          }, 2000); // 4 Seconds after page load

          // Listen for manual link clicks from iframe ads
          if (postMessageListener) {
            window.removeEventListener('message', postMessageListener, false);
          }
          postMessageListener = (event) => {
            // if (event.origin !== 'https://cdn.medifind.com') return;
            if (event.data?.type === 'linkClick') {
              manualClick(event.data.data);
            }
          };
          window.addEventListener('message', postMessageListener, false);
        }
      }, 2000); // 2 Seconds after page load
    };

    const consentLoaded = () => {
      useGoogleAnalytics.setState((state) => ({ consentLoaded: true }), false, { type: 'consentLoaded' });

      window.removeEventListener('consentLoaded', consentLoaded);
    };

    window.addEventListener('consentLoaded', consentLoaded, false);
    if (document.readyState === 'complete') {
      pageLoaded();
    } else {
      window.addEventListener('load', pageLoaded);
    }
  }
};
export const shareClicked = () => {
  useGoogleAnalytics.setState((state) => ({ shareClickCount: state.shareClickCount + 1 }), false, { type: 'shareClicked' });
};

export const trackSingleGA4Event = (singleUseId) => {
  if (!singleUseId) return true;
  if (!useGoogleAnalytics.getState().singleEventActions?.includes(singleUseId)) {
    useGoogleAnalytics.setState((state) => ({ singleEventActions: [...state.singleEventActions, singleUseId] }), false, {
      type: 'trackSingleGA4Event',
    });
    return true;
  } else {
    return false;
  }
};

export const setGA4GlobalParam = (params) => {
  useGoogleAnalytics.setState((state) => ({ globalParams: { ...state.globalParams, ...params } }), false, { type: 'setGA4GlobalParam' });
  refreshGA4Config(params);
};

export const optimizeEvent = (eventName) => {
  // Depreciated
  // if (tracking) {
  //   const tagManagerArgs = {
  //     dataLayerName,
  //     dataLayer: {
  //       event: `optimize.activate.${eventName}`,
  //     },
  //   };
  //   TagManager.dataLayer(tagManagerArgs);
  // }
};

export const consent = ({ ad = false, analytics = true, personalization = true, functionality = true, security = true }) => {
  if (TRACKING_ACTIVE) {
    const consentData = {
      consentAd: ad ? 'granted' : 'denied',
      consentAnalytics: analytics ? 'granted' : 'denied',
      consentPersonalization: personalization ? 'granted' : 'denied',
      consentFunctionality: functionality ? 'granted' : 'denied',
      consentSecurity: security ? 'granted' : 'denied',
    };
    useGoogleAnalytics.setState({ cookieAccepted: true, ...(consentData ?? {}) }, false, {
      type: 'consent',
    });

    const googleState = useGoogleAnalytics.getState();
    const tagManagerArgs = {
      dataLayerName,
      dataLayer: {
        ...Array.from(usedParams).reduce((t, a) => {
          t[a] = null;
          return t;
        }, {}), // Clear out old parameters
        ...googleState,
        ...googleState.globalParams,
        ...getSessionScopedGoogleState(),
        event: 'consent',
      },
    };
    TagManager.dataLayer(tagManagerArgs);
  }
};

const usedParams = new Set();
export const logEventGA4 = (eventName = '', parameters = {}, singleUseId = '') => {
  const googleState = useGoogleAnalytics.getState();
  const googleStateParams = { ...googleState };
  delete googleStateParams.set;
  delete googleStateParams.reset;
  delete googleStateParams.getInitialState;
  // do not register an event if the singleUseId is already used
  if (TRACKING_ACTIVE && trackSingleGA4Event(singleUseId)) {
    const tagManagerArgs = {
      dataLayerName,
      dataLayer: {
        ...Array.from(usedParams).reduce((t, a) => {
          t[a] = null;
          return t;
        }, {}), // Clear out old parameters
        event: 'ga4_event',
        ...googleStateParams,
        ...googleStateParams.globalParams,
        eventName,
        ...parameters,
        ...getSessionScopedGoogleState(),
      },
    };
    Object.keys({ eventName, ...parameters }).forEach((x) => usedParams.add(x));
    TagManager.dataLayer(tagManagerArgs);
  }
};
export const pushEvent = (eventName = '', parameters = {}) => logEventGA4(eventName, { ...parameters, event: eventName });
export const manualClick = (parameters = {}) => {
  const googleState = useGoogleAnalytics.getState();
  const googleStateParams = { ...googleState };
  delete googleStateParams.set;
  delete googleStateParams.reset;
  delete googleStateParams.getInitialState;
  // do not register an event if the singleUseId is already used
  if (TRACKING_ACTIVE) {
    const tagManagerArgs = {
      dataLayerName,
      dataLayer: {
        ...Array.from(usedParams).reduce((t, a) => {
          t[a] = null;
          return t;
        }, {}), // Clear out old parameters
        event: 'manualClick',
        ...googleStateParams,
        ...googleStateParams.globalParams,
        ...Object.entries(parameters).reduce((acc, [k, v]) => ((acc['gtm.' + k] = v), acc), {}),
        ...getSessionScopedGoogleState(),
      },
    };
    TagManager.dataLayer(tagManagerArgs);
  }
};

export const refreshGA4Config = (parameters = {}) => {
  const googleState = useGoogleAnalytics.getState();
  // We wait for trackingLoaded as ga_config can start GTM tracking before its time.
  if (TRACKING_ACTIVE && trackingLoaded) {
    const tagManagerArgs = {
      dataLayerName,
      dataLayer: {
        ...Array.from(usedParams).reduce((t, a) => {
          t[a] = null;
          return t;
        }, {}),
        eventName: undefined, // Clear out old parameters
        event: 'ga_config',
        ...googleState,
        ...googleState.globalParams,
        ...parameters,
        ...getSessionScopedGoogleState(),
      },
    };
    Object.keys({ ...googleState.globalParams, ...parameters }).forEach((x) => usedParams.add(x));
    TagManager.dataLayer(tagManagerArgs);
  }
};

function getGtmInitializationArgs(params) {
  let gtmSettingsJson = process.env.NX_GOOGLE_TAG_MANAGER;
  if (params.hostedWidgetName && process.env.NX_HOSTED_WIDGET_GTM) {
    gtmSettingsJson = process.env.NX_HOSTED_WIDGET_GTM;
  }
  if (!gtmSettingsJson) {
    return {};
  }
  const gtmSettings = JSON.parse(gtmSettingsJson);
  return createGtmInitializationArgs(gtmSettings);
}

function createGtmInitializationArgs(gtmSettings) {
  return {
    gtmId: gtmSettings.ID,
    ...(gtmSettings.AUTH
      ? {
          auth: gtmSettings.AUTH,
          preview: gtmSettings.PREVIEW,
        }
      : {}),
    dataLayerName: dataLayerName,
  };
}

function getSessionScopedGoogleState() {
  if (hostedWidgetName) {
    return { widgetName: hostedWidgetName };
  } else return {};
}
