import { QueryClient } from '@tanstack/react-query';

const MAX_RETRIES = 6;
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
      retry: (failureCount, error) => {
        if (failureCount > MAX_RETRIES) {
          return false;
        }

        if (error.code < 500) {
          return false;
        }

        return true;
      },
    },
  },
});
