import { Component, Suspense } from 'react';
import { listen } from '@medifind/router';

class ErrorBoundary extends Component {
  state = {
    uiError: null,
    info: null,
  };
  static getDerivedStateFromError(error) {
    const statusCode = parseInt(error.message);
    // statusCode.toString() === error.message prevents "2 issues with results" from being the error code 2
    return { uiError: error, statusCode: isNaN(statusCode) && statusCode.toString() === error.message ? 500 : statusCode };
  }
  componentDidMount() {
    this.unlisten = listen(() => {
      this.setState({ uiError: null, statusCode: null });
    });
  }
  componentWillUnmount() {
    if (this.unlisten) {
      this.unlisten();
      this.unlisten = null;
    }
  }
  componentDidCatch(error) {
    const statusCode = parseInt(error.message);
    // statusCode.toString() === error.message prevents "2 issues with results" from being the error code 2
    this.setState({ uiError: error, statusCode: isNaN(statusCode) && statusCode.toString() === error.message ? 500 : statusCode });
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState != this.state.uiError) {
      this.props.onUiError && this.props.onUiError(!!this.state.uiError);
    }
  }

  render() {
    const { uiError, statusCode } = this.state;
    const { errorPage: ErrorPage } = this.props;

    if (uiError) {
      return (
        <Suspense fallback={<div className="fallback" />}>
          <ErrorPage message={uiError.toString()} errorCode={statusCode} />
        </Suspense>
      );
    }
    return this.props.children;
  }
}
export { ErrorBoundary as default, ErrorBoundary };

export const withError = (WrappedComponent) => {
  return class ErrorBoundaryWrapper extends Component {
    render() {
      return (
        <ErrorBoundary>
          <WrappedComponent {...this.props} />
        </ErrorBoundary>
      );
    }
  };
};
