const redirectRouteList = ['/auth/', '/profile/', '/register', '/login', '/profile-management'];
export const pathShouldRedirect = (path) => redirectRouteList.some((route) => path.includes(route));

export const scrollToId = (id, timeout = 0, scrollOffset = 0, scrollElement = null, scrollBehavior = 'smooth') => {
  scrollElement = scrollElement || window;
  setTimeout(() => {
    const elem = document.getElementById(id);
    if (elem) {
      // if (!('scrollBehavior' in document.documentElement.style)) elem.scrollIntoView(true);
      // else

      if (scrollElement.scrollY != null) {
        // Why are we checking if window.scrollY, a read only property, is a function, even though it is obviously a number.
        // Because entyvio.com overwrote it in their codebase. Shame entyvio 😑
        scrollElement.scroll({
          top:
            elem.getBoundingClientRect().top +
            (typeof scrollElement.scrollY === 'function' ? scrollElement.scrollY() : scrollElement.scrollY) -
            15 -
            scrollOffset,
          left: 0,
          behavior: scrollBehavior,
        });
      } else {
        scrollElement.scroll({
          top: scrollElement.offsetTop + elem.offsetTop - scrollOffset,
          left: 0,
          behavior: scrollBehavior,
        });
        // elem.scrollIntoView(true); Was from the dr finder so check if somethign broke
      }
    }
  }, timeout);
};

export const isEmpty = (obj) => Object.keys(obj).length === 0 && obj.constructor === Object;

// eslint-disable-next-line eqeqeq
export const isValidProjectId = (projectId) => projectId && projectId != -1;

export const registerServiceWorker = () => {
  if (typeof navigator !== 'undefined' && 'serviceWorker' in navigator) {
    navigator.serviceWorker.register(`/sw.js`, { scope: `${process.env.NX_LOCAL_HOST}/` });
  }
};
export const registerBroadcastListener = (callback) => {
  if (typeof navigator !== 'undefined' && 'serviceWorker' in navigator) {
    const listener = async (event) => {
      if ('broadcast' in event.data) {
        callback(event.data.broadcast);
      }
    };
    navigator.serviceWorker.addEventListener('message', listener);
    return () => {
      navigator.serviceWorker.removeEventListener('message', listener);
    };
  }
};
export const broadcastMessageServiceWorker = (data) => {
  if (typeof navigator !== 'undefined' && 'serviceWorker' in navigator) {
    navigator.serviceWorker.controller?.postMessage({
      broadcast: data,
    });
  }
};

export const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
export const isTestSuite = 'MediFind-Test-Suite' === navigator.userAgent;

export const isPrerender = window.location.search.match(/[?&]prerender=true/);

export const isIOS = () => {
  const iosQuirkPresent = () => {
    const audio = new Audio();

    audio.volume = 0.5;
    return audio.volume === 1; // volume cannot be changed from "1" on iOS 12 and below
  };

  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
  const isAppleDevice = navigator.userAgent.includes('Macintosh');
  const isTouchScreen = navigator.maxTouchPoints >= 1; // true for iOS 13 (and hopefully beyond)

  return isIOS || (isAppleDevice && (isTouchScreen || iosQuirkPresent()));
};

export const dropDuplicatesByKey = (array, key) => {
  const onlyUniqueValues = (data, index, self) => self.findIndex((s, i) => s[key] === data[key] && i > index) === -1;

  return array.filter(onlyUniqueValues);
};
export const getNullList = (size, fill = null) => [...Array(size).keys()].map(() => fill);

export const toSentenceCase = (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
export const toTitleCase = (text) => text.split(' ').map(toSentenceCase).join(' ');
export const toProperLowerCase = (text) => {
  Array.from(text?.matchAll(/(\b[A-Z](?=[a-z]))/g) ?? []).forEach(({ 1: matchGroup, index }) => {
    const arr = text.split('');
    arr.splice(index, 1, matchGroup.toLowerCase());
    text = arr.join('');
  });
  return text;
};
export const isArrayEqual = (a, b) => {
  if (a.length !== b.length) {
    return false;
  }
  for (let i = 0; i < a.length; i++) {
    if (!(a[i] == null && b[i] == null) && a[i] !== b[i]) {
      return false;
    }
  }
  return true;
};
export const deepEquals = (a, b) => {
  if (Array.isArray(a) && Array.isArray(b)) {
    if (a.length !== b.length) return false;
    for (let i = 0; i < a.length; i++) if (!deepEquals(a[i], b[i])) return false;
    return true;
  } else if (a && typeof a === 'object' && b && typeof b === 'object') {
    const aEntries = Object.entries(a)
      .filter(([k, v]) => v !== undefined)
      .sort(([ak], [bk]) => ak.localeCompare(bk));
    const bEntries = Object.entries(b)
      .filter(([k, v]) => v !== undefined)
      .sort(([ak], [bk]) => ak.localeCompare(bk));
    return deepEquals(aEntries, bEntries);
  } else {
    if (a == null && b == null) return true;
    if (a === b) return true;
    return false;
  }
};

export const numberWithCommas = (num) => {
  if (num != null) {
    let res;
    const splitStr = num.toString().split('.');
    res = splitStr[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    if (!!splitStr[1]) {
      res = `${res}.${splitStr[1]}`;
    }
    return res;
  }
  return null;
};

const browserDetails = () => {
  const userAgent = navigator.userAgent;
  const browserDetail = {};

  if (userAgent.match(/chrome|chromium|crios/i)) {
    browserDetail.browserName = 'chrome';
    browserDetail.maxSize = 32779;
  } else if (userAgent.match(/firefox|fxios/i)) {
    browserDetail.browserName = 'firefox';
    browserDetail.maxSize = 64000;
  } else if (userAgent.match(/safari/i)) {
    browserDetail.browserName = 'safari';
    browserDetail.maxSize = 64000;
  } else if (userAgent.match(/edg/i)) {
    browserDetail.browserName = 'edge';
    browserDetail.maxSize = 2047;
  } else {
    browserDetail.browserName = 'No browser detection';
    browserDetail.maxSize = 0;
  }
  return browserDetail;
};

const getSizeInBytes = (obj) => {
  const reqObjSize = 1280; // approx size of rest of request object without params
  const str = typeof obj === 'string' ? obj : JSON.stringify(obj);
  const bytes = new TextEncoder().encode(str).length + reqObjSize;
  return bytes;
};

const logSizeInKilobytes = (obj) => {
  const bytes = getSizeInBytes(obj);
  const kb = (bytes / 1000).toFixed(2);
  return kb;
};

export const setGet = (obj) => {
  const objSize = getSizeInBytes(obj);
  return objSize > 20480 ? false : objSize < browserDetails().maxSize;
};

export const getTime = (time) => {
  let hour = time?.split(':')[0];
  const minutes = time?.split(':')[1];
  const meredian = hour >= 12 ? 'PM' : 'AM';
  hour = meredian === 'PM' && hour > 12 ? hour - 12 : hour;
  return `${hour > 0 ? hour : 12}:${minutes} ${meredian}`;
};

const allDays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

export const getToday = () => {
  const d = new Date();
  return allDays[d.getDay()];
};
