import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

const storeName = 'app-version';
export const useAppVersion = create(
  devtools(
    (set) => ({
      appVersion: document.querySelector('meta[name="version"]')?.content,
      appUpdated: false,
    }),
    {
      name: `${process.env.NX_APP}`,
      store: storeName,
    },
  ),
);

export const useAppVersionPoll = ({ pollInterval = 30000, disable = false }) => {
  const { appVersion } = useAppVersion((state) => state);

  const { data: newVersion, isPending: isPendingVersion } = useQuery({
    queryKey: ['version'],
    queryFn: async () => {
      try {
        const res = await window.fetch('/version', {
          headers: {
            'Content-Type': 'text/plain',
          },
        });
        if (!res.ok) {
          return appVersion;
        }
        const newVersion = (await res.text())?.trim();
        return newVersion;
      } catch (e) {
        return appVersion;
      }
    },
    refetchInterval: pollInterval, // Poll every 30 seconds,
    refetchOnWindowFocus: true,
    enabled: !disable,
  });

  useEffect(() => {
    if (!!newVersion && !isPendingVersion && appVersion != null && appVersion !== newVersion) {
      useAppVersion.setState({ appVersion: newVersion, appUpdated: true }, true);
    }
  }, [appVersion, newVersion]);
};
