import { forwardRef } from 'react';
import classNames from 'classnames';
import { Link } from 'wouter';
import useLocation from 'wouter/use-location';
import { useLazyRoutePreload } from '@medifind/hooks';
import { useLinkWithConfirmation } from '@medifind/router';
import { Skeleton } from '@medifind/shared-basic-components';
import { checkForUndefined } from '@medifind/utils';
import { pushEvent } from '@medifind/zustand';

const parseUrl = (url, origin) => {
  try {
    return new URL(url, origin);
  } catch {
    return null;
  }
};

const getURL = (url) => {
  const hasValidProtocol = url && (url.indexOf('mailto:') === 0 || url.indexOf('tel:') === 0 || url.indexOf('#') === 0);
  const urlHasDomain = url && !hasValidProtocol && url.includes('://');
  const urlObj = urlHasDomain ? parseUrl(url) : !hasValidProtocol ? parseUrl(url, window.location.origin) : undefined;
  return { urlObj, hasValidProtocol };
};

const handleAnalytics = ({ linkProps, url, urlObj, currentUrl, event }) => {
  pushEvent('link_click', {
    link_click: {
      link_id: linkProps.id,
      link_url: urlObj ? urlObj.href : url,
      link_domain: urlObj ? urlObj.hostname : window.location.hostname,
      link_type: linkProps['data-link-type'],
      link_classes: linkProps.className,
      link_element: event?.currentTarget,
      page_path: currentUrl.pathname,
      page_url: currentUrl.href,
    },
  });
};

const MFLink = forwardRef(
  (
    {
      target,
      children,
      fallback,
      external,
      clickOverride,
      noTabIndex,
      rel,
      routes,
      disabled,
      style = {},
      className,
      analyticsLinkType,
      ...props
    },
    ref,
  ) => {
    const url = props.to || props.href;
    const { linkProps } = useLinkWithConfirmation();
    checkForUndefined(url);
    checkForUndefined(external);
    const [location] = useLocation();
    const { urlObj, hasValidProtocol } = getURL(url);
    const { urlObj: currentUrl } = getURL(location);
    useLazyRoutePreload({ paths: [url], routes }, 10000);

    if (!url && fallback) {
      return <Skeleton {...fallback} />;
    } else if (disabled) {
      return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
        <span {...(noTabIndex ? { tabIndex: '-1' } : {})} style={style} className={className} {...props} onClick={null}>
          {children}
        </span>
      );
    } else if (url && (url.indexOf(process.env.NX_LOCAL_HOST) === 0 || url.indexOf('/') !== 0 || hasValidProtocol || target === '_blank')) {
      return (
        <a
          {...linkProps({
            ...(analyticsLinkType && { 'data-link-type': analyticsLinkType }),
            ...(noTabIndex ? { tabIndex: '-1' } : {}),
            onClick: clickOverride,
            style: style,
            className: className,
            ...props,
            href: url.includes('://') || url.indexOf('/') === 0 || hasValidProtocol ? url : `https://${url}`,
            target: target,
            rel: target === '_blank' ? Array.from(new Set([...(rel?.split(' ') || []), 'noopener'])).join(' ') : rel,
            handleAnalytics: (props, event) => handleAnalytics({ linkProps: props, currentUrl, url, urlObj, event }),
          })}
        >
          {children}
        </a>
      );
    } else if (url) {
      return (
        <Link
          {...linkProps({
            rel: rel,
            target: target,
            ...(noTabIndex ? { tabIndex: '-1' } : {}),
            onClick: clickOverride,
            className: className,
            style: style,
            ...(analyticsLinkType && { 'data-link-type': analyticsLinkType }),
            ...props,
            handleAnalytics: (props, event) => handleAnalytics({ linkProps: props, currentUrl, url, urlObj, event }),
          })}
        >
          {!children ? <>&zwnj;</> : Array.isArray(children) ? children : [children]}
        </Link>
      );
    } else {
      return (
        <span
          {...linkProps({
            ref: ref,
            id: props.id,
            tabIndex: noTabIndex ? '-1' : '0',
            ...(props.onClick
              ? {
                  onClick: props.onClick,
                  // role: 'button',
                  onKeyDown: (e) => {
                    if (e.key === 'Enter') props.onClick();
                  },
                }
              : {}),
            className: classNames(className, 'mf-link'),
            style: { ...style, ...(props.onClick ? { cursor: 'pointer' } : {}) },
          })}
        >
          {children}
        </span>
      );
    }
  },
);
const MFNavLink = forwardRef(({ children, activeClassName, className, matchRoute, alts, isActive, ...props }, ref) => {
  return (
    <MFLink {...props} className={classNames(className, { [activeClassName]: isActive })} ref={ref}>
      {children}
    </MFLink>
  );
});
MFNavLink.displayName = 'MFNavLink';
MFLink.displayName = 'MFLink';
export { MFLink as default, MFLink, MFNavLink };
