import { generateURL } from '@medifind/router';
import { deauthenticate, setAuthRedirect } from '@medifind/zustand';

export const getNewController = () => {
  const clientController = new AbortController();
  const signal = clientController.signal;
  return { signal, clientController };
};

const defaultHeaders = {
  'Content-Type': 'application/json',
  Authorization: null,
  ...(window.medifindAPIKey ? { 'x-api-key': window.medifindAPIKey } : {}),
};

export const setAuthorizationHeader = (token) => {
  if (token) {
    defaultHeaders.Authorization = `Bearer ${token}`;
  } else {
    defaultHeaders.Authorization = null;
  }
};
export const hasAuthorizationToken = (token) => {
  return !!defaultHeaders.Authorization;
};

const handleResponseError = async (err, endpoint) => {
  const response = await err.text();
  const contentType = err?.headers?.get('Content-Type');
  const status = err.status;
  // Note statusText is not allowed due to HTTP/2
  if (!response || !contentType?.includes('application/json')) {
    let errorMessage = response ?? 'Unknown Error';
    if (contentType?.includes('text/html')) {
      errorMessage = 'Unable to connect. Try Again.';
    }

    const e = new Error(errorMessage);
    e.code = status;
    throw e;
  }
  const responseJson = JSON.parse(response);
  const urlPath = window.location.pathname;
  const previousUrl = window.location.href;

  if (status === 401 && endpoint !== '/user/sign-out') {
    if (process.env.NX_APP === 'MediFind-Pro') {
      if (status === 401 && urlPath !== '/') {
        if (responseJson?.error === 'no-account') {
          await setAuthRedirect('page', previousUrl);
          window.location.href = '/';
        } else {
          await deauthenticate();
          await setAuthRedirect('page', previousUrl);
          window.location.href = '/';
        }
      }
    } else {
      if (status === 401) {
        if (
          responseJson.errorCode === 'admin_unauthorized' ||
          (urlPath !== '/profile-management/admin-login' && endpoint.includes('/user/profile-management/admin-login'))
        ) {
          window.location = '/profile-management/admin-login';
        } else if (urlPath !== '/login' && endpoint !== '/user/sign-in') {
          // exclude user endpoint: /user/sign-in
          // because if someone incorrectly enters the password their filters get cleared
          await deauthenticate();
        }
      }
    }
  }
  const e = new Error(responseJson?.message ?? 'Unknown Error');
  // responseJson.error cannot be used as it is removed in production
  e.code = status;
  // Copy any other fields into the error
  Object.keys(responseJson)
    .filter((key) => !['error', 'message'].includes(key))
    .reduce((e, key) => {
      e[key] = responseJson[key];
      return e;
    }, e);
  throw e;
};

export async function clientFetch(
  endpoint,
  { method = 'GET', body, params, baseUrl, signal: externalSignal, ...customConfig } = {},
  externalController,
) {
  const clientController = externalSignal ? null : externalController ? externalController : new AbortController();
  const config = {
    method,
    ...customConfig,
    headers: {
      ...defaultHeaders,
      ...customConfig?.headers,
    },
    // default timeout 30 seconds
    timeout: customConfig?.timeout || 30000,
  };
  if (body) {
    config.body = JSON.stringify(body);
  }

  const requestTimeout = clientController && setTimeout(() => clientController.abort(), config?.timeout);
  const signal = externalSignal ? externalSignal : clientController.signal;
  const endpointBaseUrl = baseUrl ? baseUrl : process.env.NX_API_HOST;

  try {
    const endpointUrl = `${endpointBaseUrl}${endpoint}`;
    const url = generateURL(endpointUrl, params, 'axios');
    const response = await window.fetch(url, { ...config, signal });
    clientController && clearTimeout(requestTimeout);
    if (response.ok) {
      const contentType = response.headers.get('content-type');
      if (contentType && contentType.indexOf('application/json') !== -1) {
        return response.json();
      } else {
        return response.text();
      }
    } else {
      // response interceptor here
      return await handleResponseError(response, endpoint);
    }
  } catch (err) {
    if (err?.name === 'AbortError') {
      throw new Error('Request Aborted');
    } else {
      console.error(err);
      throw err;
    }
  }
}

const controllerStore = {};
export async function abortableFetch(endpoint, options, name) {
  if (controllerStore[name]) controllerStore[name].abort();
  controllerStore[name] = new AbortController();
  return clientFetch(endpoint, options, controllerStore[name]).then((res) => {
    delete controllerStore[name];
    return res;
  });
}
