import { lazy } from 'react';
const DoctorSitemapPage = lazy(() => import('./pages/directory/sitemapPage'));
const ArticlesClinicalTrials = lazy(() => import('./pages/articles/clinical-trials'));
const ArticlesClinicalTrialsArticle = lazy(() => import('./pages/articles/clinical-trials-article'));
const ArticlesDrugs = lazy(() => import('./pages/articles/drugs'));
const ArticlesDrugsArticle = lazy(() => import('./pages/articles/drugs-article'));
const ArticlesTreatmentAdvances = lazy(() => import('./pages/articles/treatment-advances'));
const ArticlesTreatmentAdvancesArticle = lazy(() => import('./pages/articles/treatment-advances-article'));
const AuthChange = lazy(() => import('./pages/auth/change'));
const OAuthLoginCallback = lazy(() => import('./pages/auth/oAuthLoginCallback'));
const AuthLogin = lazy(() => import('./pages/auth/login'));
const AuthLogout = lazy(() => import('./pages/auth/logout'));
const AuthRecover = lazy(() => import('./pages/auth/recover'));
const VerifyEmail = lazy(() => import('./pages/auth/verify'));
const AuthRegister = lazy(() => import('./pages/auth/register'));
const AuthReset = lazy(() => import('./pages/auth/reset'));
const ClinicalTrials = lazy(() => import('./pages/clinical-trials'));
const ConditionBox = lazy(() => import('@medifind/lazy-loadable/lib/shared-basic-components/headings/LazyConditionBox'));
const ProcedureBox = lazy(() => import('@medifind/lazy-loadable/lib/shared-basic-components/headings/LazyProcedureBox'));
const SpecialtyBox = lazy(() => import('@medifind/lazy-loadable/lib/shared-basic-components/headings/LazySpecialtyBox'));
const VerifyUserGuard = lazy(() => import('@medifind/lazy-loadable/lib/shared-basic-components/utils/LazyVerifyUserGuard'));
const ProfileManagementBox = lazy(() => import('@medifind/lazy-loadable/lib/shared-basic-components/headings/LazyProfileManagementBox'));
const ProfileManagementAdminBox = lazy(() =>
  import('@medifind/lazy-loadable/lib/shared-basic-components/headings/LazyProfileManagementAdminBox'),
);
const DoctorBox = lazy(() => import('@medifind/lazy-loadable/lib/shared-basic-components/headings/LazyDoctorBox'));
const DrugBox = lazy(() => import('@medifind/lazy-loadable/lib/shared-basic-components/headings/LazyDrugBox'));
const SymptomBox = lazy(() => import('@medifind/lazy-loadable/lib/shared-basic-components/headings/LazySymptomBox'));
const SecondOpinionBox = lazy(() => import('@medifind/lazy-loadable/lib/shared-basic-components/headings/LazySecondOpinionBox'));
const SearchDoctorBox = lazy(() => import('@medifind/lazy-loadable/lib/shared-basic-components/headings/LazySearchDoctorBox'));
const InfusionCenterBox = lazy(() => import('@medifind/lazy-loadable/lib/shared-basic-components/headings/LazyInfusionCenterBox'));
const CodingsDoctors = lazy(() => import('./pages/codings/doctors'));
const CodingsSingle = lazy(() => import('./pages/codings/codings-single'));
const ConditionSearch = lazy(() => import('./pages/condition-search'));
const ContactContact = lazy(() => import('./pages/contact/contact'));
const ContactProContact = lazy(() => import('./pages/contact/pro-contact'));
const DirectoryConditions = lazy(() => import('./pages/directory/conditions'));
const DirectoryDoctors = lazy(() => import('./pages/directory/doctors'));
const DirectorySymptoms = lazy(() => import('./pages/directory/symptoms'));
const DirectoryProcedures = lazy(() => import('./pages/directory/procedures'));
const DoctorsSingle = lazy(() => import('./pages/doctors/doctors-single'));
const Faq = lazy(() => import('./pages/faq'));
const SearchDoctor = lazy(() => import('./pages/search-doctor/SearchDoctor.js'));
const HowMediFindWorks = lazy(() => import('./pages/how-medifind-works'));
const Index = lazy(() => import('./pages/index'));
const FindADoctorStub = lazy(() => import('./pages/find-a-doctor-stub'));
const Landing = lazy(() => import('./pages/landing'));
const LandingPatientConnect = lazy(() => import('./pages/landing-pages/patient-connect'));
const UnsubscribeEmail = lazy(() => import('./pages/landing-pages/unsubscribe'));
const LatestResearch = lazy(() => import('./pages/latest-research'));
const LegalPolicies = lazy(() => import('./pages/legal/policies'));
const LegalPrivacy = lazy(() => import('./pages/legal/privacy'));
const LegalTerms = lazy(() => import('./pages/legal/terms'));
const HealthDataPrivacy = lazy(() => import('./pages/legal/health-data-privacy'));
const ContentPolicy = lazy(() => import('./pages/legal/content-policy'));
const MediFindStory = lazy(() => import('./pages/medifind-story'));
const News = lazy(() => import('./pages/news/index'));
const NewsPost = lazy(() => import('./pages/news/post'));
const PartnerWithMediFind = lazy(() => import('./pages/partner-with-medifind'));
const Profile = lazy(() => import('./pages/profile'));
const ProfileAccount = lazy(() => import('./pages/profile/account'));
const ProfileNotifications = lazy(() => import('./pages/profile/notifications'));
const ProfileDashboard = lazy(() => import('./pages/profile/dashboard'));
const SecondOpinionResults = lazy(() => import('./pages/second-opinion/results'));
const SecondOpinionSearch = lazy(() => import('./pages/second-opinion/search'));
const SecondOpinionSplash = lazy(() => import('./pages/second-opinion/splash'));
const SpecialtyFindSpecialist = lazy(() => import('./pages/specialty/find-specialist'));
const SymptomChecker = lazy(() => import('./pages/symptom-checker'));
const EntyvioDoctorFinder = lazy(() => import('./pages/doctor-finder-pages/entyvio-doctor-finder'));
const GenericDoctorFinder = lazy(() => import('./pages/doctor-finder-pages/generic-doctor-finder'));
const FindADoctorHostedDemo = lazy(() => import('./pages/doctor-finder-pages/find-a-doctor-hosted-demo'));
const HPPDoctorFinder = lazy(() => import('./pages/doctor-finder-pages/hpp-doctor-finder'));
const AmgenDoctorFinder = lazy(() => import('./pages/doctor-finder-pages/amgen-doctor-finder'));
const CenteneDoctorFinder = lazy(() => import('./pages/doctor-finder-pages/centene-doctor-finder'));
const InfusionCenterFinder = lazy(() => import('./pages/infusion-center/infusion-center-finder'));
const EntyvioInfusionCenterFinder = lazy(() => import('./pages/infusion-center/entyvio-infusion-center-finder'));
const InfusionCenterAdd = lazy(() => import('./pages/infusion-center/infusion-center-add'));
const SymptomArticle = lazy(() => import('./pages/symptoms/single'));
const PatientRegistry = lazy(() => import('./pages/patient-registry/PatientRegistry'));
const RareCare = lazy(() => import('./pages/patient-registry/RareCare'));
const ClinicalTrialSignUp = lazy(() => import('./pages/clinical-trials/trial-sign-up'));
const PartnerScheduling = lazy(() => import('./pages/patient-registry/PartnerScheduling'));
const ProfileManagementSignUp = lazy(() => import('./pages/profile-management/profile-management-sign-up'));
const ProfileManagementDoctors = lazy(() => import('./pages/profile-management/profile-management-doctors.js'));
const ProfileManagementDoctorsNew = lazy(() => import('./pages/profile-management/profile-management-doctors-new.js'));
const ProfileManagementDoctorsEdit = lazy(() => import('./pages/profile-management/profile-management-doctors-edit.js'));
const ProfileManagementAdminSignIn = lazy(() => import('./pages/profile-management/admin/profile-management-admin-sign-in'));
const ProfileManagementAdminSignInCallback = lazy(() =>
  import('./pages/profile-management/admin/profile-management-admin-sign-in-callback'),
);
const ProfileManagementAdminSearch = lazy(() => import('./pages/profile-management/admin/profile-management-admin-search'));
const ProfileManagementAdminPending = lazy(() => import('./pages/profile-management/admin/profile-management-admin-pending'));
const ProfileManagementAdminInfusionCenterSearch = lazy(() =>
  import('./pages/profile-management/admin/profile-management-admin-infusion-center-search'),
);
const ProfileManagementAdminInfusionCenterPending = lazy(() =>
  import('./pages/profile-management/admin/profile-management-admin-infusion-center-pending'),
);
const ProfileManagementAdminEditInfusionCenter = lazy(() =>
  import('./pages/profile-management/admin/profile-management-admin-edit-infusion-center'),
);
const ProfileManagementAdminDoctorsEdit = lazy(() => import('./pages/profile-management/admin/profile-management-admin-edit'));
const ProfileManagementAdminUsers = lazy(() => import('./pages/profile-management/admin/profile-management-admin-users'));
const ProfileManagementUsers = lazy(() => import('./pages/profile-management/profile-management-users.js'));
const ProfileManagementSettings = lazy(() => import('./pages/profile-management/profile-management-settings.js'));
const ProfileManagementDashboard = lazy(() => import('./pages/profile-management/profile-management-dashboard.js'));
const ProfileManagementOrganization = lazy(() => import('./pages/profile-management/profile-management-organization.js'));
const ProfileManagementAppointmentsCenter = lazy(() => import('./pages/profile-management/profile-management-appointments.js'));
const ProfileManagementAppointmentDetails = lazy(() => import('./pages/profile-management/profile-management-appointment-details.js'));
const ProfileManagementDashboardData = lazy(() => import('./pages/profile-management/profile-management-dashboard-data'));

export const commonRoutes = [
  { path: '/conditions/:codingSlug/:projectId/:rest*', component: ConditionBox },
  { path: '/procedures/:codingSlug/:projectId/:rest*', component: ProcedureBox },
  { path: '/specialty/:specialtySlug/:rest*', component: SpecialtyBox },
  { path: '/doctors/:personSlug/:personId/(treatment-advances|publications|clinical-trials)', component: DoctorBox },
  { path: '/second-opinion/results', component: SecondOpinionBox },
  { path: '/drugs/:drugId/(treatment-advances|clinical-trials)', component: DrugBox },
  { path: '/symptoms/:symptomSlug', component: SymptomBox },
  { path: '/profile-management/admin/callback', render: () => null },
  { path: '/profile-management/admin/:rest*', component: ProfileManagementAdminBox },
  { path: '/profile-management/doctors/edit/:profileManagementTeamId/:profileManagementId', component: ProfileManagementBox },
  {
    path: '/profile-management/(dashboard|users|settings|dashboard-data|doctors|organization|appointments)/:rest*',
    component: ProfileManagementBox,
  },
  { path: '/infusion-center/add', component: InfusionCenterBox },
  { path: '/infusion-center/update', component: InfusionCenterBox },
  { path: '/search', component: SearchDoctorBox },
];
/* NOTE: Always ensure the routes have the relevant __ROUTE_PATH__ annotation, and they may have multiple */
export const routes = [
  { path: '/', component: Index },
  { path: '/find-a-doctor', component: FindADoctorStub },
  { path: '/l/patient-connect', component: LandingPatientConnect },
  { path: '/email-unsubscribe', component: UnsubscribeEmail },
  /* Auth Routes */
  { path: '/login', component: AuthLogin },
  { path: '/:source/login/callback', component: OAuthLoginCallback },
  { path: '/logout', component: AuthLogout },
  { path: '/recover', component: AuthRecover },
  { path: '/reset', component: AuthReset },
  { path: '/change', component: AuthChange },
  { path: '/register', component: AuthRegister },
  { path: '/verify-email', component: VerifyEmail },
  /* Conditions/Procedure Routes */
  { path: '/(conditions|procedures)/:codingSlug/:projectId', component: CodingsSingle },
  { path: '/(conditions|procedures)/:codingSlug/:projectId/drugs', component: ArticlesDrugs },
  { path: '/(conditions|procedures)/:codingSlug/:projectId/drug/:drugId', component: ArticlesDrugsArticle },
  { path: '/drugs/:drugId', component: ArticlesDrugsArticle },
  { path: '/(conditions|procedures)/:codingSlug/:projectId/doctors', component: CodingsDoctors },
  {
    path: '/(conditions|procedures)/:codingSlug/:projectId/doctors/location/:countryCode?/:stateProvinceCode?/:city?',
    component: CodingsDoctors,
  },
  { path: '/(conditions|procedures)/:codingSlug/:projectId/doctors/drug/:drugId', component: CodingsDoctors },
  { path: '/(conditions|procedures)/:codingSlug/:projectId/doctors/:personSlug/:personId', component: DoctorsSingle },
  /* Articles Routes */
  { path: '/drugs/:drugId/treatment-advances', component: ArticlesTreatmentAdvances },
  { path: '/drugs/:drugId/clinical-trials', component: ArticlesClinicalTrials },
  { path: '/(conditions|procedures)/:codingSlug/:projectId/treatment-advances', component: ArticlesTreatmentAdvances },
  { path: '/doctors/:personSlug/:personId/treatment-advances', component: ArticlesTreatmentAdvances },
  { path: '/(conditions|procedures)/:codingSlug/:projectId/publications', component: ArticlesTreatmentAdvances },
  { path: '/doctors/:personSlug/:personId/publications', component: ArticlesTreatmentAdvances },
  { path: '/(conditions|procedures)/:codingSlug/:projectId/clinical-trials', component: ArticlesClinicalTrials },
  { path: '/doctors/:personSlug/:personId/clinical-trials', component: ArticlesClinicalTrials },
  { path: '/(conditions|procedures)/:codingSlug/:projectId/clinical-trial/:articleId', component: ArticlesClinicalTrialsArticle },
  { path: '/doctors/:personSlug/:personId/clinical-trial/:articleId', component: ArticlesClinicalTrialsArticle },
  { path: '/articles/clinical-trial/:articleId', component: ArticlesClinicalTrialsArticle },
  { path: '/(conditions|procedures)/:codingSlug/:projectId/treatment-advance/:articleId', component: ArticlesTreatmentAdvancesArticle },
  { path: '/doctors/:personSlug/:personId/treatment-advance/:articleId', component: ArticlesTreatmentAdvancesArticle },
  { path: '/articles/treatment-advance/:articleId', component: ArticlesTreatmentAdvancesArticle },
  { path: '/(conditions|procedures)/:codingSlug/:projectId/publication/:articleId', component: ArticlesTreatmentAdvancesArticle },
  { path: '/doctors/:personSlug/:personId/publication/:articleId', component: ArticlesTreatmentAdvancesArticle },
  { path: '/articles/publication/:articleId', component: ArticlesTreatmentAdvancesArticle },
  /* Doctor-Finder Routes*/
  { path: '/(entyvio|takeda)-doctor-finder/:specialtySlug', component: EntyvioDoctorFinder },
  { path: '/(entyvio|takeda)-doctor-finder/:specialtySlug/:codingSlug', component: EntyvioDoctorFinder },
  { path: '/specialty-doctor-finder/:specialtySlug', component: GenericDoctorFinder },
  { path: '/hpp-doctor-finder', component: HPPDoctorFinder },
  { path: '/osteoporosis-bone-health-doctor-finder', component: AmgenDoctorFinder },
  { path: '/ambetter-doctor-finder', component: CenteneDoctorFinder },
  { path: '/specialty-doctor-finder/:specialtySlug/:codingSlug', component: GenericDoctorFinder },
  { path: '/condition-doctor-finder/:codingSlug', component: GenericDoctorFinder },
  { path: '/hosted-doctor-finder/asthma', component: FindADoctorHostedDemo },
  { path: '/infusion-center/finder', component: InfusionCenterFinder },
  { path: '/entyviohcp-infusion-finder', component: EntyvioInfusionCenterFinder },
  { path: '/infusion-center/add', component: InfusionCenterAdd },
  { path: '/infusion-center/update', component: InfusionCenterAdd },
  /* Doctors Routes */
  { path: '/doctors/:personSlug/:personId', component: DoctorsSingle },
  { path: '/specialty/:specialtySlug/near-me', component: SpecialtyFindSpecialist },
  { path: '/specialty/:specialtySlug/:countryCode?/:stateProvinceCode?/:city?', component: SpecialtyFindSpecialist },
  /* Second Opinion Routes */
  { path: '/second-opinion', component: SecondOpinionSplash },
  { path: '/second-opinion/search', component: SecondOpinionSearch },
  { path: '/second-opinion/results', component: SecondOpinionResults },
  /* Splash Pages */
  { path: '/clinical-trials', component: ClinicalTrials },
  { path: '/search', component: SearchDoctor },
  { path: '/condition-search', component: ConditionSearch },
  { path: '/latest-research', component: LatestResearch },
  /* Symptom Checker Pages */
  { path: '/symptom-checker', component: SymptomChecker },
  /* Symptom Pages */
  { path: '/symptoms/:symptomSlug', component: SymptomArticle },
  /* User Profile Routes */
  { path: '/profile/account', component: ProfileAccount },
  { path: '/profile/notifications', component: ProfileNotifications },
  { path: '/profile/dashboard/:codingSlug/:projectId', component: ProfileDashboard },
  { path: '/profile/dashboard/:codingSlug', component: ProfileDashboard },
  { path: '/profile/dashboard', component: ProfileDashboard },
  // { path: '/profile/notifications', component: ProfileNotifications },
  { path: '/profile', component: Profile },
  /* Directory Pages */
  { path: '/directory/doctors', component: DirectoryDoctors },
  { path: '/directory/sitemap/:page', component: DoctorSitemapPage },
  { path: '/directory/doctors/condition/:codingSlug/:continentCode?/:countryCode?/:stateProvinceCode?', component: DirectoryDoctors },
  { path: '/directory/doctors/specialty/:specialtySlug/:continentCode?/:countryCode?/:stateProvinceCode?', component: DirectoryDoctors },
  { path: '/directory/conditions', component: DirectoryConditions },
  { path: '/specialty', component: DirectoryDoctors },
  { path: '/directory/symptoms', component: DirectorySymptoms },
  { path: '/directory/procedures', component: DirectoryProcedures },
  /* Main Pages */
  { path: '/contact', component: ContactContact },
  { path: '/pro-contact', component: ContactProContact },
  { path: '/how-medifind-works', component: HowMediFindWorks },
  { path: '/medifind-story', component: MediFindStory },
  { path: '/partner-with-medifind', component: PartnerWithMediFind },
  { path: '/policies', component: LegalPolicies },
  { path: '/privacy', component: LegalPrivacy },
  { path: '/terms', component: LegalTerms },
  { path: '/health-data-privacy', component: HealthDataPrivacy },
  { path: '/content-policy', component: ContentPolicy },
  { path: '/faq', component: Faq },
  { path: '/h/:slug', component: Landing },
  /* Newsroom Pages */
  { path: '/news', component: News },
  { path: '/news/post/:slug', component: NewsPost },
  { path: '/_preview/:id/:rev/:type/:status/:wpnonce', component: NewsPost },
  /* Patient Registry */
  { path: '/patient-registry/:codingSlug/:projectId', component: PatientRegistry },
  /* RareCare Assistance Sign Up */
  { path: '/rare-care/:codingSlug/:projectId', component: RareCare },
  { path: '/clinical-trial-sign-up/:codingSlug/:projectId/:articleId', component: ClinicalTrialSignUp },
  { path: '/partner-scheduling/:partnerTeamId/specialty/:specialtySlug', component: PartnerScheduling },
  { path: '/partner-scheduling/:partnerTeamId/(conditions|procedures)/:codingSlug/:projectId', component: PartnerScheduling },
  /* Profile Management */
  { path: '/cyp', component: ProfileManagementSignUp },
  { path: '/profile-management/sign-up', component: ProfileManagementSignUp },
  { path: '/profile-management/dashboard', render: () => <VerifyUserGuard component={ProfileManagementDashboard} /> },
  { path: '/profile-management/doctors', render: () => <VerifyUserGuard component={ProfileManagementDoctors} /> },
  { path: '/profile-management/doctors/new', render: () => <VerifyUserGuard component={ProfileManagementDoctorsNew} /> },
  {
    path: '/profile-management/doctors/edit/:profileManagementTeamId/:profileManagementId',
    render: () => <VerifyUserGuard component={ProfileManagementDoctorsEdit} />,
  },
  { path: '/profile-management/organization', render: () => <VerifyUserGuard component={ProfileManagementOrganization} /> },
  { path: '/profile-management/admin/dashboard', render: () => <VerifyUserGuard component={ProfileManagementAdminSearch} /> },
  { path: '/profile-management/admin/pending', render: () => <VerifyUserGuard component={ProfileManagementAdminPending} /> },
  {
    path: '/profile-management/admin/infusion-center-pending',
    render: () => <VerifyUserGuard component={ProfileManagementAdminInfusionCenterPending} />,
  },
  {
    path: '/profile-management/admin/edit-infusion-center/:internalId',
    render: () => <VerifyUserGuard component={ProfileManagementAdminEditInfusionCenter} />,
  },
  {
    path: '/profile-management/admin/infusion-center/search',
    render: () => <VerifyUserGuard component={ProfileManagementAdminInfusionCenterSearch} />,
  },
  { path: '/profile-management/admin/search', render: () => <VerifyUserGuard component={ProfileManagementAdminSearch} /> },
  { path: '/profile-management/admin/edit/:personId', render: () => <VerifyUserGuard component={ProfileManagementAdminDoctorsEdit} /> },
  {
    path: '/profile-management/admin/edit/:personId/:adminId',
    render: () => <VerifyUserGuard component={ProfileManagementAdminDoctorsEdit} />,
  },
  { path: '/profile-management/admin-login', render: () => <VerifyUserGuard component={ProfileManagementAdminSignIn} /> },
  { path: '/profile-management/admin/users', render: () => <VerifyUserGuard component={ProfileManagementAdminUsers} /> },
  { path: '/profile-management/users', render: () => <VerifyUserGuard component={ProfileManagementUsers} /> },
  { path: '/profile-management/dashboard-data', render: () => <VerifyUserGuard component={ProfileManagementDashboardData} /> },
  { path: '/profile-management/settings', render: () => <VerifyUserGuard component={ProfileManagementSettings} /> },
  { path: '/profile-management/appointments', render: () => <VerifyUserGuard component={ProfileManagementAppointmentsCenter} /> },
  {
    path: '/profile-management/appointments/:appointmentId',
    render: () => <VerifyUserGuard component={ProfileManagementAppointmentDetails} />,
  },
  { path: '/profile-management/admin/callback', render: () => <VerifyUserGuard component={ProfileManagementAdminSignInCallback} /> },
];
