import { Suspense, useEffect } from 'react';
import { useRoute } from 'wouter';
import useLocation from 'wouter/use-location';
import { RouteContext, useRouter } from '@medifind/router';
import { setAdditionalRouteParams, setRouteParams, useRouteStore } from '@medifind/zustand';

const StoreLogic = ({ component: Component, render }) => {
  const router = useRouter();

  const routeUUID = useRouteStore((state) => state.routeUUID);
  useEffect(() => {
    setRouteParams({ pathParams: router.pathParams, queryParams: router.queryParams, params: router.query });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.wouterLocation]);

  useEffect(() => {
    if (routeUUID) setAdditionalRouteParams({ ...router.queryParams, ...router.query }, routeUUID);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.queryParams, routeUUID]);

  return render ? render() : <Component />;
};
const MFRoute = ({ path, pathExclude = [], component: Component, render, passRouteStoreParams = true, suspense, mainRoute, ...props }) => {
  const [match, params] = useRoute(path);
  const [location] = useLocation();
  if (!match) return null;
  if (pathExclude.some((p) => location.match(p))) return null;

  let route;
  if (passRouteStoreParams) route = <StoreLogic component={Component} render={render} />;
  else route = render ? render() : <Component />;
  route = <RouteContext.Provider value={params}>{route}</RouteContext.Provider>;
  // The main route is used by the prerender to indicate the page has loaded
  if (mainRoute) route = <div className="main">{route}</div>;
  if (suspense) return <Suspense fallback={<div className="fallback" />}>{route}</Suspense>;
  else return route;
};

export { MFRoute as default, MFRoute };
