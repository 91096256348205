import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { createRoot } from 'react-dom/client';
import '@medifind/styles/common/index.scss';
import { queryClient } from '@medifind/utils';
import Router from './router';

createRoot(document.getElementById('mf-root')).render(
  <QueryClientProvider client={queryClient}>
    {process.env.NX_ENV === 'LOCAL' ? <ReactQueryDevtools initialIsOpen={false} /> : null}
    <Router />
  </QueryClientProvider>,
);
